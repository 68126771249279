<template>
  <v-card
    :loading="loadingTradeReviewDetail"
    fill-height
    flat
    class="wl-page-module"
  >
    <v-card-title>
      Trade Review Detail
    </v-card-title>
    <v-card-text v-if="loadingTradeReviewDetail">
      <v-skeleton-loader type="table-tbody" />
    </v-card-text>
    <v-card-text v-if="!loadingTradeReviewDetail">
      <v-text-field
        label="Search Trade Details"
        v-model="searchTradeReviewValue"
        outlined
        color="bobGreen"
        @keydown="initSearch"
      />
      <v-data-table
        :headers="headers"
        :items="tradeReviewAccountItems"
        :search="searchTradeReview"
        :page="page"
      ></v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { AdvisorTradeReviewAccountColumns } from './lib/columns'
import { v4 } from 'uuid'

export default {
  props: {
    tradeReviewId: { type: String, default: undefined, required: true },
  },
  computed: {
    ...mapGetters({}),
    tradeReviewAccountItems() {
      return (this.tradeReviewDetail?.accounts || []).map((t) => ({
        id: v4(),
        ...t,
        select: true,
        actions: true,
      }))
    },
  },
  watch: {
    tradeReviewId() {
      this.loadTradeDetail()
    },
  },
  data() {
    return {
      headers: AdvisorTradeReviewAccountColumns,
      searchTradeReviewValue: undefined,
      searchTradeReview: undefined,
      page: 1,
      tradeReviewDetail: undefined,
      loadingTradeReviewDetail: true,
    }
  },
  async mounted() {
    await this.loadTradeDetail()
  },
  methods: {
    ...mapActions({
      getTradeReviewDetail: 'tradeReview/getTradeReviewDetail',
    }),
    initSearch($event) {
      this.debounce = setTimeout(() => {
        this.searchTradeReview = this.searchTradeReviewValue
        this.page = 1
      }, 1000)
      if ($event.key === 'Enter') {
        this.searchTradeReview = this.searchTradeReviewValue
        this.page = 1
        clearTimeout(this.debounce)
      }
    },
    async loadTradeDetail() {
      this.loadingTradeReviewDetail = true
      this.tradeReviewDetail = await this.getTradeReviewDetail(
        this.tradeReviewId
      )
      this.loadingTradeReviewDetail = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
