<template>
  <v-card flat>
    <v-card-text>
      <v-flex row fill-height>
        <v-flex xs12 md12 lg12 fill-height>
          <AdvisorTradeReview />
        </v-flex>
      </v-flex>
    </v-card-text>
  </v-card>
</template>

<script>
import AdvisorTradeReview from '@/components/AdvisorTradeReview'
export default {
  components: {
    AdvisorTradeReview,
  },
  metaInfo: {
    title: `Trade Review`,
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
